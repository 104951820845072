<template>
	<Layout>
		<v-container fluid>
			<v-row>
				<v-col cols="12">
					<v-row>
						<v-col cols="12" sm="8">
								<h2> <v-icon large>mdi-account-group</v-icon> USUARIOS REGISTRADOS ({{users.length}})</h2>
						</v-col>
							<v-col cols="12" sm="4">
								<v-text-field
									class="text-right"
									v-model="search"
									append-icon="mdi-magnify"
									label="Search"
									single-line
									hide-details
								></v-text-field>
							</v-col>
					</v-row>
				</v-col>
				<v-col cols="12">

						<v-alert info>
							Filtrar:
							<a @click.stop="filtrarRegistros('Todos')">Todos</a> |
							<a @click.stop="filtrarRegistros('Miembros')">Miembros</a> |
							<a @click.stop="filtrarRegistros('No-Miembros')">Dermatólogos No-Miembros</a> |
							<a @click.stop="filtrarRegistros('Otras')">Otras Especialidades</a> |
							<a @click.stop="filtrarRegistros('Estudiantes')">Estudiantes</a> |
							<a @click.stop="filtrarRegistros('Industria')">Industria</a>
							<p><strong>TOTAL {{subTotalTitle}}: {{ usersFilter.length }}</strong></p>
							<p v-if="totalMiembros"><strong>MIEMBROS: {{ totalTitular }} Titulares. {{ totalActivo }} Activos. {{ totalAsociado }} Asociados</strong></p>
						</v-alert>
						<v-data-table
							:headers="headers"
							:items="usersFilter"
							item-key="_id"
							class="elevation-1"
							:search="search"
							:loading="loading"
							loading-text="Cargando... Espere"
							no-data-text="Sin registros para mostrar"
							:footer-props="{
								'items-per-page-options': [50, 100, 200]
							}"
							:items-per-page="50"
						>

							<!-- <template v-slot:item.email="{ item }">
								<span :class="item.check_token ? 'green--text darken-4' : 'red--text darken-4'">
								{{ item.email }}
								</span>
							</template> -->
							<template v-slot:item.photo="{ item }">

							<img :src="item.photo ? item.photo : 'https://res.cloudinary.com/svdmqe-cloud/image/upload/v1635886550/pictures/user_ciunta.jpg' " width="30" />


							</template>
							<template v-slot:item.miembro="{ item }">
								{{ item.miembro == '' ? 'NO' : item.miembro}}
							</template>
							<template v-slot:item.solvente="{ item }">
								<span :class="item.check_token ? 'green--text darken-4' : 'red--text darken-4'">
								<v-chip
									class="ma-2"
									:color="item.solvente ? 'green darken-4' : 'red darken-4'"
									text-color="white"
								>
									{{ item.solvente ? 'SI' : 'NO'}}
								</v-chip>
								</span>
							</template>
							<template v-slot:item.verificado="{ item }">
								<span :class="item.check_token ? 'green--text darken-4' : 'red--text darken-4'">
								<v-chip
									class="ma-2"
									:color="item.verificado ? 'green darken-4' : 'red darken-4'"
									text-color="white"
								>
									{{ item.verificado ? 'SI' : 'NO'}}
								</v-chip>
								</span>
							</template>
							<!-- columna accion -->
							<template v-slot:item.action="{ item }">
								<v-icon  class="botones_list" color="primary"  @click="editProfileUser(item)">
									mdi-chevron-double-right
								</v-icon>
								<!-- <v-icon  class="botones_list" color="primary"  @click="lowerCaseToggleEmail(item)">
									mdi-mail
								</v-icon> -->
								<!-- <v-icon  class="botones_list" color="red lighten-2"  @click="removeUser(item)">
									mdi-delete
								</v-icon> -->
							</template>

						</v-data-table>

				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" sm="6" class="text-center">
					<download-excel
						:data = "usersFilter"
						type    = "csv"
						:fields = "json_fields"
						name="registros.xls"
					>
							<a>Descargar Listado {{subTotalTitle}}:</a>
							<v-icon color="primary"> mdi-download</v-icon>
					</download-excel>
				</v-col>
			</v-row>
		</v-container>
	</Layout>


</template>

<script>
	import axios from 'axios';
	import store from '@/store'
	const header = {"Token": store.state.userModule.token}
	const configuration = {headers: header}
	export default {
		name: 'UserList',
		data: () => ({
			subTotalTitle: 'Todos',
			header_icon: '',
			header_title: '',
			users: [],
			usersFilter: [],
			search: '',
			loading: false,
			totalMiembros: false,
			totalTitular: 0,
			totalActivo: 0,
			totalAsociado: 0,
			headers: [
				{ text: '', align: 'start', value: 'photo'},
				// { text: 'ID', align: 'start', value: '_id'},
				{ text: 'CEDULA', align: 'start', value: 'cedula'},
				{ text: 'APELLIDO', align: 'start', value: 'apellido'},
				{ text: 'NOMBRE', align: 'start', value: 'nombre'},
				{ text: 'EMAIL', align: 'start', value: 'email'},
				{ text: 'CIUDAD', value: 'ciudad'},
				{ text: 'ESTADO', value: 'estado'},
				{ text: 'CATEGORIA', value: 'categoria'},
				{ text: 'MIEMBRO', value: 'miembro'},
				{ text: 'SOLVENTE', value: 'solvente'},
				{ text: 'VERIFICADO', value: 'verificado'},
				{text: '', value: 'action', sortable: false},
			],
			json_fields: {
				'CEDULA': 'cedula',
				'NOMBRE': 'nombre',
				'APELLIDO': 'apellido',
				'SEXO': 'sexo',
				'EMAIL': 'email',
				'TELEFONO': 'telefono',
				'CATEGORIA': 'categoria',
				'MIEMBRO': 'miembro',
				'TRABAJO': 'trabajo',
				'ESPECIALIDAD': 'especialidad',
				'INSTITUCION': 'institucion',
				'VERIFICADO': 'verificado',
				'DIRECCION': 'direccion',
				'CIUDAD': 'ciudad',
				'ESTADO': 'estado',
				'PAIS': 'pais'

			},

		}),
		mounted(){
			this.getData()
			this.createBread()
		},
		methods: {
			async getData(){
				let me = this;
				//const categoria = me.$route.params.type == 'miembros' ? 'Dermatologo Miembro'  : 'General'
				const type = me.$route.params.type

        await axios.get('user/list', configuration)
            .then(
                function (response) {
									me.users = response.data;
									me.usersFilter = response.data;

									me.loading = false

                })
            .catch(function (e) {
							me.loading = false
              console.log(e.message)
            })
			},
			editProfileUser(item) {
				this.$router.push({name: 'UserEdit', params: {id: item._id}})
			},
			async lowerCaseToggleEmail(item){

				item.email = item.email.toLowerCase()

				console.log(item._id)
				console.log(item.email)

				let me = this;
				this.saving = true
				await axios.put('user/update-email', {
					_id: item._id,
					email: item.email,
				}, configuration)
					.then(function(response){
						me.$store.commit('SET_ALERT_MESSAGE', {
							show: true,
							color: 'success',
							message: 'Usuario actualizado satisfactoriamente',
							timeout: 4000
						}, {root: true})
						me.saving = false
					})
					.catch(function (e) {
						me.saving = false
						console.log(e.message)
					})



			},
			async removeUser(item){

				const index = this.users.findIndex(x => x._id === item._id)

				await this.$confirm('Seguro de borrar este Registro?', {
					title: 'Warning',
					color: 'error',
					icon: 'mdi-hand',
					buttonTrueColor: 'primary',
					})
						.then(res => {
							if (res) {
								let me = this;
								// { data: { _id: item._id }
								axios.post('user/remove', { _id: item._id }, configuration)
									.then(function(response){
										me.users.splice(index,1)
										me.$store.commit('SET_ALERT_MESSAGE', {
											show: true,
											color: 'success',
											message: 'Usuario eliminado satisfactoriamente',
											timeout: 4000
										}, {root: true})
									})
									.catch(function (e) {
										console.log(e.message)
									})
							}
						})

			},

			createBread(){
				this.$store.dispatch('setBread',[
        {
          text: `Usuarios Registrados`,
          disabled: true,
          href: '#',
        },
				]);
			},
			filtrarRegistros(filtro){
				switch (filtro) {
					case 'Todos':
						this.usersFilter = this.users;
						this.totalMiembros = false;
						this.subTotalTitle = 'Todos';
						break;
					case 'Miembros':
						this.usersFilter = this.users.filter( x => x.categoria == 'Dermatologo Miembro');

						this.totalTitular = this.users.filter( x => x.miembro == 'Titular').length;
						this.totalActivo = this.users.filter( x => x.miembro == 'Activo').length;
						this.totalAsociado = this.users.filter( x => x.miembro == 'Asociado').length;
						this.totalMiembros = true;
						this.subTotalTitle = 'Miembros';
						break;
					case 'No-Miembros':
						this.usersFilter = this.users.filter( x => x.categoria == 'Dermatologo No-Miembro');
						this.totalMiembros = false;
						this.subTotalTitle = 'Dermatólogos No-Miembro';
						break;
					case 'Otras':
						this.usersFilter = this.users.filter( x => x.categoria == 'Otras Especialidades');
						this.totalMiembros = false;
						this.subTotalTitle = 'Otras Especialidades';
						break;
					case 'Estudiantes':
						this.usersFilter = this.users.filter( x => x.categoria == 'Estudiante');
						this.totalMiembros = false
						this.subTotalTitle = 'Estudiantes';
						break;
					case 'Industria':
						this.usersFilter = this.users.filter( x => x.categoria == 'Industria');
						this.totalMiembros = false
						this.subTotalTitle = 'Industria';
						break;
					default:
						this.usersFilter = this.users;
						this.totalMiembros = false;
						this.subTotalTitle = 'Todos';
						break;
				}

			}

		},
		computed: {
		}
	}
</script>

<style lang="scss" scoped>
	.botones_list {
		margin: 0px 10px 10px 0px;
			font-size: xx-large;
	}
</style>